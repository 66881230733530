import { gql } from '@apollo/client';
import { storePersistor } from 'configuration/redux/store';
import { getCookie, setCookie } from 'modules/common/utils/cookie';
import { requestGql } from 'modules/common/utils/request-gql';
import { CurrentBusinessPartnerInternalCookieName } from 'modules/current-business-partner-selector/constants/current-business-partner-internal-cookie-name';
import { useEffect, useState } from 'react';

export interface CurrentBusinessPartnerInternalInterface {
  id: string;
  name: string;
  kunnr?: string;
}

export interface CurrentBusinessPartnerInternalFlatInterface extends CurrentBusinessPartnerInternalInterface {
  totalCount?: number;
}

interface CurrentBusinessPartnerInternalFetchInterface {
  data: { businessPartnerInternal: CurrentBusinessPartnerInternalInterface };
  totalCount: number;
}

const fetchUserCurrentBusinessPartnerInternal = (): Promise<CurrentBusinessPartnerInternalFetchInterface> =>
  requestGql(
    {
      query: gql`
        query {
          userCurrentBusinessPartnerInternal {
            data {
              businessPartnerInternal {
                id
                name
                kunnr
              }
            }
            totalCount
          }
        }
      `,
      variables: {},
    },
    null,
    'userCurrentBusinessPartnerInternal',
  );

export const useGetCurrentBusinessPartnerInternal = (): {
  currentBusinessPartnerInternal: CurrentBusinessPartnerInternalFlatInterface | null;
  isLoading: boolean;
  setCurrentBusinessPartnerInternal: (value: CurrentBusinessPartnerInternalFlatInterface | null) => void;
  updateBusinessPartnerInternalInCookie: (value?: CurrentBusinessPartnerInternalFlatInterface) => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentBusinessPartnerInternal, setCurrentBusinessPartnerInternal] =
    useState<CurrentBusinessPartnerInternalFlatInterface | null>();

  const updateBusinessPartnerInternalInCookie = (value?: CurrentBusinessPartnerInternalFlatInterface) => {
    if (value?.id) {
      const { id, name, totalCount, kunnr } = value;
      setCookie(CurrentBusinessPartnerInternalCookieName, JSON.stringify({ id, name, totalCount, kunnr }));
      setCurrentBusinessPartnerInternal(value);
      void storePersistor.purge();
    } else if (value) {
      const { totalCount } = value;
      setCookie(CurrentBusinessPartnerInternalCookieName, JSON.stringify({ totalCount }));
      setCurrentBusinessPartnerInternal(null);
      void storePersistor.purge();
    }
  };


  const getCurrentBusinessPartnerInternal = async () => {
    setIsLoading(true);
    const valueFromCookie = getCookie(CurrentBusinessPartnerInternalCookieName);
    let result: CurrentBusinessPartnerInternalFlatInterface;

    if (valueFromCookie) {
      result = JSON.parse(valueFromCookie);
    } else {
      try {
        const remoteValue = await fetchUserCurrentBusinessPartnerInternal();

        if (remoteValue) {
          result = { ...remoteValue.data.businessPartnerInternal, totalCount: remoteValue.totalCount };
        }
      } catch (e) {
        result = null;
      }
    }
    setIsLoading(false);
    updateBusinessPartnerInternalInCookie(result);
  };


  useEffect(() => {
    void getCurrentBusinessPartnerInternal();
  }, []);

  return {
    currentBusinessPartnerInternal,
    setCurrentBusinessPartnerInternal,
    isLoading,
    updateBusinessPartnerInternalInCookie
  };
};
