import { gql } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { PaginationInterface } from 'modules/common/interfaces';
import { ProductInterface } from 'modules/common/interfaces/product.interface';
import { requestGql } from 'modules/common/utils/request-gql';

export interface ProductQueryInterface extends PaginationInterface {
  expandToOrderTypeFilter?: boolean;
  filter?: {
    kunnr: string;
    searchText?: string;
  };
}

export interface ProductPageInterface {
  data: ProductInterface[];
  totalCount: number;
}
export interface UseFetchProductHookInterface {
  fetchProducts: (query: ProductQueryInterface) => Promise<ProductPageInterface>;
}

export const useFetchProduct = (): UseFetchProductHookInterface => {
  const fetchProducts = (query: ProductQueryInterface): Promise<ProductPageInterface> =>
    requestGql(
      {
        query: gql`
          query Products($limit: Int, $offset: Int, $filter: ProductFilterArgType, $expandToOrderTypeFilter: Boolean) {
            products(limit: $limit, offset: $offset, filter: $filter, expandToOrderTypeFilter: $expandToOrderTypeFilter) {
              data {
                kunnr
                productId
                productName
                ${query.expandToOrderTypeFilter ? `orderType { salesContract salesDocument addressDetails {id displayAddress}}` : ''}
              }
              totalCount
            }
          }
        `,
        variables: !isEmpty(query)
          ? {
            limit: query.limit,
            offset: query.offset,
            filter: query.filter,
            expandToOrderTypeFilter: query.expandToOrderTypeFilter
          }
          : {},
      },
      null,
      'products',
    );

  return {
    fetchProducts,
  };
};