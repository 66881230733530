/* eslint-disable @roq/filename-suffix-mismatch */
import { InputLabel, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ProductInterface } from 'modules/common/interfaces/product.interface';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search/interfaces';
import * as React from 'react';

interface IProps {
  label?: string;
  onChange: (selectedValue: ProductAutocompleteOptionInterface) => void;
  value: ProductAutocompleteOptionInterface;
  placeholder?: string;
  multiple?: boolean;
  defaultToFirst?: boolean;
  companyKunnr?: string;
  disabled?: boolean;
  isLabel?: boolean;
  inputStyle?: SxProps;
  fetchOrderTypeFilterDetails?: boolean;
  isLoading?: boolean;
  products?: ProductInterface[];
}

export const ProductAutocompleteOverview: React.FunctionComponent<IProps> = (props: IProps) => {
  const { onChange, placeholder, defaultToFirst, disabled = false, inputStyle = {}, products, isLoading } = props;
  const [value, setValue] = React.useState<ProductAutocompleteOptionInterface>(props.value);
  const [options, setOptions] = React.useState<readonly ProductAutocompleteOptionInterface[]>([]);

  React.useEffect(() => {
    if (products?.length) {
      const mappedOption = products.map((x) => ({
        id: x.productId,
        label: x.productName,
        kunnr: x.kunnr,
      }));
      setOptions(mappedOption);
    }
  }, [products]);

  React.useEffect(() => {
    if (defaultToFirst && !value?.id && options.length >= 1) {
      onChange(options[0]);
    }
  }, [options]);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const { label = 'Search Product', isLabel = true } = props;

  return (
    <>
      {isLabel ?
        <InputLabel size='small'
          style={{
            color: disabled ? '#cbcbcc' : '#626262',
            marginBottom: '6px',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '157%',
            letterSpacing: '0.17px',
          }}
        >{label}</InputLabel >
        :
        null
      }
      <Autocomplete
        loading={isLoading}
        disabled={disabled}
        disablePortal
        options={options}
        onChange={(_: React.SyntheticEvent, newValue: ProductAutocompleteOptionInterface) => {
          onChange(newValue);
        }}
        sx={inputStyle}
        value={value}
        getOptionLabel={(option: ProductAutocompleteOptionInterface) => `${option?.label}`}
        renderInput={(params) => (
          <TextField {...params} size="small" variant="filled" placeholder={value || !disabled ? placeholder : ''} />
        )}
      />
    </>
  );
};
