/* eslint-disable @roq/filename-suffix-mismatch */
import { InputLabel, SxProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useAsyncOperation } from 'modules/common/hooks';
import { useCurrentBusinessPartnerInternal } from 'modules/current-business-partner-selector/hooks';
import { ProductAutocompleteOptionInterface } from 'modules/forms/components/product-autocomplete-search/interfaces';
import { useFetchProduct } from 'modules/forms/hooks';
import * as React from 'react';

interface IProps {
  label?: string;
  onChange: (selectedValue: ProductAutocompleteOptionInterface) => void;
  value: ProductAutocompleteOptionInterface;
  placeholder?: string;
  multiple?: boolean;
  defaultToFirst?: boolean;
  companyKunnr?: string;
  disabled?: boolean;
  isLabel?: boolean;
  inputStyle?: SxProps;
  fetchOrderTypeFilterDetails?: boolean
}

export const ProductAutocompleteSearchSingle: React.FunctionComponent<IProps> = (props: IProps) => {
  const { fetchProducts } = useFetchProduct();
  const { onChange, placeholder, defaultToFirst, companyKunnr, disabled = false, inputStyle = {} } = props;
  const [value, setValue] = React.useState<ProductAutocompleteOptionInterface>(props.value);
  const [options, setOptions] = React.useState<readonly ProductAutocompleteOptionInterface[]>([]);

  const { status, initiateOperation } = useAsyncOperation({
    callback: fetchProducts,
  });
  const { currentBusinessPartnerInternal } = useCurrentBusinessPartnerInternal();

  const resultantKunnr = React.useMemo(
    () => companyKunnr || currentBusinessPartnerInternal?.kunnr,
    [companyKunnr, currentBusinessPartnerInternal],
  );

  React.useEffect(() => {
    if (status?.result) {
      const mappedOption = status.result.data.map((x) => ({
        id: x.productId,
        label: x.productName,
        kunnr: x.kunnr,
      }));
      setOptions(mappedOption);
    }
  }, [status]);

  React.useEffect(() => {
    if (defaultToFirst && !value?.id && options.length >= 1) {
      onChange(options[0]);
    }
  }, [options]);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  React.useEffect(() => {
    if (resultantKunnr) {
      void initiateOperation({
        offset: 0,
        limit: 20,
        filter: { kunnr: resultantKunnr },
        expandToOrderTypeFilter: props?.fetchOrderTypeFilterDetails || false
      });
    }
  }, [currentBusinessPartnerInternal, resultantKunnr]);
  const { label = 'Search Product', isLabel = true } = props;

  return (
    <>
      {isLabel ?
        <InputLabel size='small'
          style={{
            color: disabled ? '#cbcbcc' : '#626262',
            marginBottom: '6px',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '157%',
            letterSpacing: '0.17px',
          }}
        >{label}</InputLabel >
        :
        null
      }
      <Autocomplete
        disabled={disabled}
        disablePortal
        options={options}
        onChange={(_: React.SyntheticEvent, newValue: ProductAutocompleteOptionInterface) => {
          onChange(newValue);
        }}
        sx={inputStyle}
        value={value}
        getOptionLabel={(option: ProductAutocompleteOptionInterface) => `${option?.label}`}
        renderInput={(params) => (
          <TextField {...params} size="small" variant="filled" placeholder={value || !disabled ? placeholder : ''} />
        )}
      />
    </>
  );
};
